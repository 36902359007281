<template>
  <form-item :error-message="errorMessage" :blocked="blocked" :model-key="modelKey" :label="labelWithOptional">
    <b-list-group>
      <b-list-group-item v-if="0 === files.length" variant="light">
        {{ $t('form.input.noFile') }}
      </b-list-group-item>

      <b-list-group-item
        v-for="file in files"
        :key="file.id"
        :variant="file.error ? 'danger' : file.done ? '' : 'light'"
      >
        <div class="d-flex justify-content-between align-items-center" data-test="attachment">
          <span
            >{{ file.name }} <span v-if="file.size">({{ file.size | prettyBytes }})</span></span
          >
          <button type="button" class="close" @click="removeFile(file)">
            <i class="icon icon-close"></i>
          </button>
        </div>
        <b-progress
          height="2px"
          v-if="file.progress < 99.99 && !file.error"
          :value="Number(file.progress || 5)"
        ></b-progress>
      </b-list-group-item>
    </b-list-group>

    <file-upload
      :input-id="modelKey"
      @focus.native="setFocus()"
      @blucr.native="removeFocus()"
      class="mt-3"
      ref="upload"
      :extensions="allowedExtensions"
      :size="maxFileSize"
      :custom-action="uploadFile"
      :multiple="true"
      @input-file="onAfterAddFile"
      v-model="files"
    >
      <por-btn variant="secondary">{{ $t('form.input.add') }}</por-btn>
    </file-upload>
  </form-item>
</template>

<script>
import FileUpload from 'vue-upload-component';
import { BListGroup, BListGroupItem, BProgress } from 'bootstrap-vue';
import isEqual from 'lodash/isEqual';
import PorBtn from 'poronline-shared-vue-components/components/por-btn';
import withModel from 'poronline-shared-vue-components/components/form/with-model';
import prettyBytes from 'poronline-shared-vue-components/services/pretty-bytes';
import { defaultFileSize, defaultFileExtensions } from '../../services/validators/files';
import * as http from '../../services/http';

export default {
  name: 'InputFile',
  mixins: [withModel],
  components: { FileUpload, BListGroup, BListGroupItem, BProgress, PorBtn },
  filters: { prettyBytes },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    simpleFiles() {
      return (this.files || []).map((file) => ({
        ...{ size: file.size, fileName: file.name, done: file.done },
        ...file.response,
      }));
    },
    allowedExtensions() {
      return (this.validators && this.validators.files && this.validators.files.extensions) || defaultFileExtensions;
    },
    maxFileSize() {
      return (this.validators && this.validators.files && this.validators.files.maxSize) || defaultFileSize;
    },
    maxFiles() {
      return (this.validators && this.validators.files && this.validators.files.maxFiles) || 0;
    },
  },
  created() {
    if (this.hadPreexistingValueOnModel) {
      this.files = this.model[this.modelKey].map(this.dummyFileObjectFor);
    }
  },
  methods: {
    uploadFile(file, component) {
      const formData = new FormData();
      formData.append('file', file.file);

      return http
        .post('/api/hamis/forms/attachments', formData, {}, ({ loaded, total }) => {
          component.update(file, {
            progress: Math.min((loaded / total) * 100, 99),
          });
        })
        .then(({ data }) => {
          component.update(file, { response: data, progress: 100, success: true, done: true });
        })
        .catch(({ response }) => {
          this.$refs.upload.remove(file);
          if (400 === response?.status) {
            this.$notify({ type: 'danger', text: this.$t('form.input.notAcceptedFile') });
          }
        });
    },
    removeFile(file) {
      this.$refs.upload.remove(file);
      this.$refs.upload.active = true;
    },
    onAfterAddFile(file) {
      this.setDirty();

      this.files.forEach((f) => {
        if (f.error && f !== file) {
          this.$refs.upload.remove(f);
        }
      });
      this.$refs.upload.active = true;
    },
    dummyFileObjectFor(file) {
      return {
        name: file.fileName,
        size: file.size,
        type: file.mimeType,
        response: file,
        id: file.externalId,
        data: {},
        done: true,
        fileObject: false,
        active: false,
        error: '',
        success: true,
        timeout: 0,
        file: null,
        progress: 100,
        speed: 0,
        headers: {},
      };
    },
  },
  watch: {
    simpleFiles() {
      const value = this.simpleFiles.length > 0 ? this.simpleFiles : undefined;
      if (!isEqual(value, this.value)) {
        this.value = value;
      }
    },
  },
};
</script>
